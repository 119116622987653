'use client'

import { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { Message } from '@locmod/intl'
import clsx from 'clsx'
import { useClientOnly } from 'hooks'
import {
  Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel, Transition,
} from '@headlessui/react'
import { constants } from 'helpers'
import { useDevice } from 'contexts'

import { Button, buttonMessages } from 'components/inputs'
import { ActiveLink, Href } from 'components/navigation'
import { Icon, Logo } from 'components/ui'
import { navMessages } from 'compositions/nav'


type BaseItem = {
  title: Intl.Message | string
  to?: string
  toTab?: string
}

type Item = BaseItem & {
  items?: BaseItem[]
}

const items: Item[] = [
  {
    title: navMessages.home,
    to: '/',
  },
  {
    title: buttonMessages.build,
    items: [
      {
        title: navMessages.buildersHub,
        to: constants.links.buildersHub,
      },
      {
        title: navMessages.docs,
        toTab: constants.links.docs,
      },
      {
        title: 'Github',
        toTab: constants.links.github,
      },
    ],
  },
  {
    title: navMessages.explore,
    items: [
      {
        title: navMessages.azuroscan,
        toTab: '',
      },
      {
        title: navMessages.community,
        toTab: constants.links.discord,
      },
      {
        title: navMessages.blog,
        toTab: 'https://medium.com/azuroprotocol?source=logo-lo_fb5bb370f9fc---444720f3e363',
      },
    ],
  },
  // {
  //   title: constants.azurToken.name,
  //   to: '/token',
  // },
]

type RenderItemsProps = {
  items: Item[]
  itemClassName: string
  dropdownClassName: string
}

const RenderItems: React.FC<RenderItemsProps> = (props) => {
  const { items, itemClassName, dropdownClassName } = props

  return (
    <>
      {
        items.map((item, index) => {
          const { title, items, to, toTab } = item

          if (items) {
            return (
              <Menu as="div" className="relative" key={index}>
                <MenuButton className={itemClassName}>
                  {
                    ({ open }) => (
                      <>
                        <Message value={title} />
                        <Icon className={clsx('size-4 ml-1 -mr-1 opacity-50', open && 'rotate-180')} name="interface/chevron-down" />
                      </>
                    )
                  }
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className={dropdownClassName}>
                    {
                      items.map(({ to, toTab, title }, index) => {

                        if (typeof toTab !== 'undefined') {
                          const className = clsx(itemClassName, {
                            'select-none cursor-default sm:!bg-white/70 !text-grey-80': !toTab,
                          })

                          return (
                            <MenuItem
                              key={index}
                              as={Href}
                              className={className}
                              toTab={toTab}
                              disabled={!toTab}
                            >
                              <Message value={title} html />
                              <Icon className="ml-1 size-3.5 opacity-30" name="interface/extrernal-link" />
                            </MenuItem>
                          )
                        }

                        return (
                          <MenuItem
                            key={index}
                            as={ActiveLink}
                            className={itemClassName}
                            activeClassName="bg-brand-50/10"
                            message={title}
                            to={to}
                          />
                        )
                      })
                    }
                  </MenuItems>
                </Transition>
              </Menu>
            )
          }

          if (toTab) {
            return (
              <Href
                className={itemClassName}
                key={index}
                message={title}
                toTab={toTab}
              />
            )
          }

          return (
            <ActiveLink
              key={index}
              className={itemClassName}
              activeClassName="backdrop-blur-2 bg-brand-50/20"
              message={title}
              to={to}
            />
          )
        })
      }
    </>
  )
}

const rootClassName = clsx(
  'w-container fixed top-0 left-0 right-0 h-14 sm:h-18 flex p-2 z-30 items-center justify-between mt-top-notice',
  '-sm:rounded-b-6 -sm:border-b -sm:border-white/20 -sm:bg-white/70 -sm:backdrop-blur-2',
  'sm:mt-2'
)

const MobilePanel = () => {
  const isVisible = useClientOnly()
  const { isMobileInjectedBrowser } = useDevice()

  if (!isVisible) {
    return null
  }

  return createPortal(
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <PopoverPanel className="fixed size-full top-0 left-0 z-20 flex flex-col pt-20 px-5 bg-light/60 backdrop-blur-8 overflow-y-auto mt-top-notice">
        <RenderItems
          items={items}
          itemClassName="w-full flex items-center justify-center mt-1 py-2.5 px-4 text-land-h4 text-center text-deep-blue rounded-full transition"
          dropdownClassName="relative py-1 px-2 bg-white/70 rounded-6"
        />
        <Button
          className="mt-12 mx-auto"
          title={navMessages.apps}
          to="/ecosystem"
          styling="land-ocean-20"
          size={56}
        />
        <Button
          className="mt-12 mx-auto"
          title={buttonMessages.launchPortal}
          toTab={isMobileInjectedBrowser ? undefined : constants.links.app}
          to={isMobileInjectedBrowser ? constants.links.app : undefined}
          styling="land-gradient-ocean"
          size={56}
        />
      </PopoverPanel>
    </Transition>,
    document.getElementById('modals')!
  )
}

const TopNav: React.FC = () => {
  const { isMobileInjectedBrowser } = useDevice()

  return (
    <nav className={rootClassName}>
      <Href to="/" aria-label="Home">
        <Logo className="w-32 h-6" />
        {/*<Logo className="w-32 h-6 fill-white hidden [.dark-nav_&]:block drop-shadow-[0_2px_2px_white]" fillInherit="all" />*/}
      </Href>
      <div className="ml-auto rounded-full flex items-center p-2 sm:bg-white/70 sm:backdrop-blur-2">
        <div className="hidden sm:flex items-center">
          <RenderItems
            items={items}
            itemClassName="h-10 flex items-center py-2.5 px-4 text-land-body-3 text-deep-blue rounded-full whitespace-nowrap transition hocus:backdrop-blur-2 hocus:bg-azuro-sky/50"
            dropdownClassName="absolute top-12 mt-0.5 min-w-32 w-fit left-1/2 -translate-x-1/2 z-20 flex flex-col sm:bg-white/70 sm:backdrop-blur-2 shadow-lvl3 rounded-6 p-1"
          />
        </div>
        <Button
          className=""
          title={navMessages.apps}
          to="/ecosystem"
          styling="land-ocean-20"
          size={40}
        />
        <Button
          className="w-32 ml-2"
          title={buttonMessages.launchPortal}
          toTab={isMobileInjectedBrowser ? undefined : constants.links.app}
          to={isMobileInjectedBrowser ? constants.links.app : undefined}
          styling="land-gradient-ocean"
          size={40}
        />
      </div>
      <Popover as="div" className="sm:hidden ml-4 z-10">
        <PopoverButton
          className="size-10 relative z-30 flex flex-col items-center justify-center bg-brand-50/20 rounded-full outline-0"
          type="button"
          aria-label="Open menu"
        >
          <div className="w-3 h-[2px] bg-deep-blue ui-open:rotate-45 ui-open:translate-y-[2px] transition" />
          <div className="w-3 h-[2px] bg-deep-blue mt-[2px] ui-open:-rotate-45 ui-open:-translate-y-[2px] transition" />
        </PopoverButton>
        <MobilePanel />
      </Popover>
    </nav>
  )
}

export default TopNav
